<template>
    <div class="base-counter">
        <div
            class="base-counter__button"
            @click="subCount"
        >
            <IconMinus class="base-counter__button-icon"/>
        </div>
        <input
            type="number"
            v-model="count"
            :min="minCount"
            :max="maxCount"
            style="appearance: textfield;"
        >
        <div
            class="base-counter__button"
            @click="addCount"
        >
            <IconPlus class="base-counter__button-icon"/>
        </div>
    </div>
</template>

<script>
import IconMinus from '../Icons/IconMinus.vue';
import IconPlus from '../Icons/IconPlus.vue';
export default {
    name: 'BaseCounter',
    components: {
        IconMinus,
        IconPlus
    },
    props: {
        minCount: {
            type: Number,
            default: 0
        },
        maxCount: {
            type: Number,
            default: 0
        }
    },
    data: () => ({
        count: 0
    }),
    watch: {
        count() {
            if (this.count > this.maxCount)
                this.count = this.maxCount;
            else if (this.count < this.minCount)
                this.count = this.minCount;
            else
                this.count = parseInt(this.count);
            
            this.$emit('updateCount', this.count);
        }
    },
    mounted() {
        this.count = this.minCount;
    },
    methods: {
        addCount() {
            ++this.count;
        },
        subCount() {
            --this.count;
        }
    }
}
</script>

<style lang="scss" scoped>
    .base-counter {
        display: flex;

        input[type="number"] {
            width: 36px;
            height: 36px;
            border: 1px solid #dcdfe5;
            border-radius: 10px;
            display: inline-block;
            text-align: center;
            margin-right: 4px;

            &:active, &:hover, &:focus {
                outline: 0;
                outline-offset: 0;
            }

            &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }

        &__button {
            background: #fff;
            font-size: 14px;
            font-weight: bold;
            text-align: center;
            line-height: 34px;
            border: 1px solid #dcdfe5;
            border-left-color: rgb(220, 223, 229);
            border-left-style: solid;
            border-left-width: 1px;
            cursor: pointer;
            outline: 0;
            -webkit-transition: all 250ms;
            -moz-transition: all 250ms;
            transition: all 250ms;

            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            border-radius: 10px;
            padding: 0;
            margin-right: 4px;

            &-icon {
                width: 18px;
                height: 18px;
            }
        }
    }
</style>