<template>
    <AppTable
        primaryKey="id"
        :columns="columns"
        :rows="rows"
        class="reconciliation-import-marking-table"
        @onScroll="onScrollTable"
    >
        <template v-slot:infoError="scopeData">
            <IconAlarm v-if="getRowIsInfoError(scopeData)"/>
        </template>
        <template v-slot:markingValue="scopeData">
            <!-- <BaseSelectModal
                :items="getRowMarkingValueOptions(scopeData)"
                :itemsSelected="getRowMarkingValue(scopeData)"
                placeholderDefault="Не размечено"
                title="Выберите значение"
                class="reconciliation-import-marking-table__select"
                @onSelectItems="onSelectItemsRowMarkingValue($event, scopeData)"
                @onToggle="onToggleSelectModal"
            /> -->
            <BaseSelectMultipleV2
                :ref="getRowSelectRef(scopeData)"
                :isFixed="true"
                :canSearch="true"
                :options="getRowMarkingValueOptions(scopeData)"
                :value="getRowMarkingValueSelect(scopeData)"
                :defaultTitle="'Не размечено'"
                :tooltipMaxLength="27"
                class="reconciliation-import-marking-table__select"
                @check="onSelectItemsRowMarkingValueSelect($event, scopeData)"
            />
        </template>
    </AppTable>
</template>

<script>
import AppTable from '../../Table2/Table.vue';
// import BaseSelectModal from '../../Base/BaseSelectModal.vue';
import BaseSelectMultipleV2 from '../../Base/BaseSelectMultipleV2.vue';
import IconAlarm from '../../Icons/IconAlarm.vue';
export default {
    name: 'ReconciliationImportMarkingTable',
    components: {
        AppTable,
        // BaseSelectModal,
        BaseSelectMultipleV2,
        IconAlarm
    },
    props: {
        columns: {
            type: Array,
            default: () => ([])
        },
        rows: {
            type: Array,
            default: () => ([])
        },
        markingValues: {
            type: Array,
            default: () => ([])
        }
    },
    created() {
		this.$parent.$on('$scrollReconciliationModalImport', this.onScrollReconciliationModalImport);
	},
    methods: {
        getRowIsInfoError(scopeData = {}) {
            const {cell = false} = scopeData;
            return Boolean(cell);
        },
        getRowSelectRef(scopeData = {}) {
            return `tableSelect-${this.getRowKey(scopeData)}`;
        },
        getRowKey(scopeData = {}) {
            const {row = {}} = scopeData;
            return String(row?.id ?? '');
        },
        getRowMarkingValueOptions(scopeData = {}) {
            const markingValue = this.getRowMarkingValue(scopeData);
            let markingValues = [...this.markingValues];
            markingValue.forEach(markingValueItem => {
                const {type = ''} = markingValueItem;
                
                if (String(markingValueItem?.id) !== '') {
                    let markingValueNew = {...markingValueItem};
    
                    if (String(type) === 'multi')
                        markingValueNew.dates = [];
                    
                    markingValues = [markingValueNew, ...markingValues]
                }
            }) 
            return markingValues;
        },
        getRowMarkingValueSelect(scopeData = {}) {
            const markingValue = this.getRowMarkingValue(scopeData);
            const markingValueSelect = markingValue.map(markingValueItem => String(markingValueItem?.id ?? ''));

            return markingValueSelect;
        },
        getRowMarkingValue(scopeData = {}) {
            const {cell = []} = scopeData;
            return cell;
        },
        onScrollTable() {
            Object.values(this.$refs).forEach((tableSelect) => {
                tableSelect?.onClose();
            });
        },
        onSelectItemsRowMarkingValueSelect(itemsIdsSelected = [], scopeData = {}) {
            const markingValueOptions = this.getRowMarkingValueOptions(scopeData);
            const itemsSelected = itemsIdsSelected.map(itemId => markingValueOptions?.find(option => String(option?.id ?? '') === String(itemId)) ?? {});

            this.onSelectItemsRowMarkingValue(itemsSelected, scopeData);
        },
        onSelectItemsRowMarkingValue(itemsSelected = [], scopeData = {}) {
            const {row = {}} = scopeData;
            this.$emit('onSelectItemsTable', String(row?.id ?? ''), itemsSelected);
        },
        onToggleSelectModal(isOpen = false) {
            this.$emit('onToggleSelectModal', isOpen);
        },
        onScrollReconciliationModalImport() {
            this.onScrollTable();
        }
    }
}
</script>

<style lang="scss" scoped>
    .reconciliation-import-marking-table {
        // max-height: 200px;

        &__select {
            width: 400px !important;
        }

        ::v-deep .table {
            height: 50px;
        }
    }
</style>